// primary colors
$primary-color: #da063e;
$primary-color-text: #fff;

// text colors
$text-color: rgba(255, 255, 255, 0.87);
$text-color-secondary: rgba(255, 255, 255, 0.6);
$text-color-info: #5d5d5d;
$text-topic-color: #95989a;

// surface colors
$surface-a: #151718;
$surface-b: #202223;
$surface-c: #272727;
$surface-d: #383838;
$surface-e: #121212;
$surface-f: #202223;
$surface-g: #161718;
$surface-h: #1b1d1e;
