/* You can add global styles to this file, and also import other style files */
@import 'primeng/resources/primeng.css';
@import 'primeicons/primeicons.css';
@import './assets/_fonts.scss';
@import './assets/_colors.scss';
@import './assets/_mixins.scss';
@import './assets/themes/gamers-theme.css';
@import 'primeflex/primeflex.scss';
@include avatarGenerator;
@include badgeGenerator;

html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: $surface-f;
  background-image: url('https://cdn.stage.gamers.ba/assets/images/Partial_Background.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  font-family: Ubuntu, sans-serif;

  .premium-card {
    box-shadow: none;
    padding: 15px;
    border: 2px solid $text-color-info;
    border-radius: 4px;
    transition: border 0.15s ease;

    &:hover {
      border-color: $primary-color;
    }

    .p-card-header {
      padding-bottom: 15px;
    }

    .p-card-body {
      @include styleclass('flex flex-column');
      gap: 15px;
      .p-card-subtitle {
        margin: 0;
      }
      .p-card-content {
        margin: 0;
        max-height: fit-content;

        h1 {
          margin: 0;
          font-size: 1rem;
          color: $primary-color;
        }
      }
      .p-card-footer {
        @include styleclass('flex justify-content-between');
        span {
          font-size: 32px;
          font-weight: bold;

          sup {
            font-size: 18px;
          }
        }
      }
    }
    .p-card-footer {
      border: none;
    }
  }

  .p-overlaypanel {
    border-radius: 3px;

    .p-overlaypanel-content {
      padding: 0;
      div {
        padding: 5px;
        background-color: $primary-color;
        color: #e6e6e6;
        border-bottom: 1px solid #000000;
        cursor: pointer;

        &:first-child {
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
        }
        &:last-child {
          border-bottom-left-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }
    }
  }

  .p-dialog.auth {
    width: 550px;
    .p-dialog-header {
      background: transparent;
      color: $primary-color;
      justify-content: flex-start;

      .p-dialog-header-icons {
        span {
          color: $primary-color;
        }
      }
    }

    .p-checkbox-box {
      background: transparent;
      border: 1px solid black;
      border-radius: 4px;
    }

    .p-checkbox-label {
      color: $text-color-info;
      font-size: 14px;
    }
  }

  .p-editor-container.forum-post {
    .p-editor-toolbar {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    .p-editor-content {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .p-editor-toolbar,
    .p-editor-content {
      border: 1px solid black;
    }
  }
}

/*
*
* OVERRIDE DEFAULT COMPONENTS
*
*/

.gamers-container {
  &-header {
    &-dropdown.p-overlaypanel {
      border-radius: 4px;
      background: $primary-color;

      .p-overlaypanel-content {
        @include styleclass('p-0');
      }
    }

    &-dropdown-item {
      padding: 10px 15px;

      &:not(:last-child) {
        border-bottom: 1px solid black;
      }
    }
  }
}

/* /OVERRIDE DEFAULT COMPONENT */

.gba-button {
  border: 2px solid $primary-color;
  border-radius: 4px;
  padding: 15px 20px;
  background: transparent;
  text-transform: uppercase;
  color: $primary-color;
  font-size: 16px;
  letter-spacing: 2px;
  cursor: pointer;
  width: fit-content;

  &:disabled {
    color: $surface-d;
    border-color: $surface-d;
    cursor: default;
  }
}

.full-width {
  width: 100% !important;
}

.primary-color {
  color: $primary-color !important;
}
.white-color {
  color: #ffffff !important;
}
.secondary-color {
  color: #3b3b3b !important;
}
.avatar-wrapper {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  img {
    border: 5px solid #da063e;
    position: relative;
  }
  .level-badge {
    position: absolute;
    img {
      position: relative;
      border: none;
    }
    span {
      position: absolute;
    }
  }
}

// body .p-dialog {
//   & > .p-dialog-header {
//     background: transparent;
//     color: $primary-color;
//     justify-content: flex-start;
//     .p-dialog-header-icons {
//       span {
//         color: $primary-color;
//       }
//     }
//   }
// }

/* ----- NEWS MEGA MENU ----- */
.news-menu {
  .p-megamenu {
    min-height: 71px;
    border-radius: 5px;
    background-color: $primary-color;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
    padding: 0;

    &-root-list {
      justify-content: center;

      .p-menuitem {
        height: 69px;
        &.active {
          a {
            border-bottom: 6px solid #ffffff;
          }
        }
        &-link {
          border-bottom: 6px solid transparent;
          height: 100%;
          &:hover {
            border-bottom: 6px solid #ffffff;
          }
        }
        &-text {
          font-size: 18px;
          padding-top: 6px;
        }
      }
    }
  }
}
/* ----- NEWS MEGA MENU ----- */

/* ----- HOMEPAGE SHARED STYLES----- */
.homepage-component {
  &-title {
    @include styleclass('mt-0');
    height: 25px;
    font-size: 22px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    margin: 0;
  }

  &:first-child {
    background: blue;
  }

  &-header {
    @include styleclass('flex');
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    span {
      color: $primary-color;
      font-weight: bold;
      display: flex;
      align-items: center;
      gap: 10px;
      @include styleclass('cursor-pointer');

      &::after {
        content: '\2794';
        font-size: 20px;
      }
    }
  }

  &-list-gap {
    @include styleclass('flex');
    flex-direction: column;
    gap: 14px;

    &-item {
      @include styleclass('flex justify-content-center');
      flex-direction: column;
      height: 80px;
      padding: 10px;
      background-color: #161718;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);

      &-wrapper {
        @include styleclass('flex');
        align-items: center;
        justify-content: space-between;

        h1 {
          margin: 0;
        }

        &-description {
          @include styleclass('flex');
          align-items: center;
          gap: 20px;

          &-items {
            @include styleclass('flex');
            flex-direction: column;

            &:not(:last-child) {
              border-right: 1px solid black;
            }

            span {
              text-align: center;
              color: white;
              font-size: 16px;

              &:first-child {
                color: $text-color-info;
              }
            }

            &-wrapper {
              @include styleclass('flex');
              font-weight: bold;

              span {
                font-size: 18px;
              }
              span:first-child {
                text-transform: uppercase;
                font-size: 12px;
              }

              & > :first-child,
              & > :last-child {
                color: $primary-color;
              }

              & > * {
                padding: 0 20px;
              }
            }
          }
        }
      }
    }
  }

  &-list {
    @include styleclass('flex');
    flex-direction: column;

    &-item {
      @include styleclass('flex align-items-center');
      color: white;
      gap: 10px;
      background-color: #161718;
      padding: 0 15px;
      height: 91px;
      border-left: 5px $primary-color solid;
      border-bottom: 1px solid black;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);

      &-empty {
        background: $surface-g;
        @include styleclass('p-3 text-white');
      }

      &:last-child {
        border-bottom: none;
      }

      &-content {
        @include styleclass('flex pl-2');
        flex-direction: column;
        gap: 3px;
      }

      &-date {
        @include styleclass('flex');
        flex-direction: column;
        background: $primary-color;
        border-radius: 6px;
        padding: 10px;
        text-align: center;
        width: 60px;
        height: 60px;

        span {
          &:first-child {
            font-weight: bold;
          }
        }
      }

      &-title {
        color: #ffffff;
        font-size: 20px;
        font-weight: 500;
        @include styleclass('m-0 cursor-pointer');
      }

      &-info {
        @include styleclass('m-0');
        color: $text-color-info;
        span {
          color: $text-color-info;

          &:first-child {
            color: $primary-color;
          }
        }
      }
    }
  }

  &-item {
    @include styleclass('flex justify-content-center');
    flex-direction: column;
    height: 80px;
    padding: 10px;
    background-color: #161718;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
  }
}
/* ----- HOMEPAGE SHARED STYLES----- */

/* -----   CARD   ----- */
.latest-news-card,
.featured-news-card,
.most-visited-news-card,
.latest-events,
.upcoming-events {
  .p-card {
    cursor: pointer;
    .p-card-header {
      position: relative;
      img {
        width: 100%;
        opacity: 0.6;
        object-fit: cover;
      }
      img.esport-image {
        border-top-left-radius: 5px;
      }
    }
    .p-card-body {
      .p-card-subtitle {
        padding: 0 1rem;
        color: #3b3b3b;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 24px;
      }
      .p-card-footer {
        & .news-info {
          width: 75%;
          height: 53px;
          display: flex;
          align-items: center;
          padding-left: 0.5rem;
          span {
            &.vertical-separator {
              width: 1px;
              border-left: 1px solid #000000;
              height: 20px;
              margin: 3%;
            }
            &.category {
              color: $primary-color;
              font-size: 12px;
              font-weight: 500;
              letter-spacing: 0;
            }
            &.published {
              color: #3b3b3b;
              font-size: 12px;
              letter-spacing: 0;
              width: 63px;
            }
            &.organization {
              color: $primary-color;
              font-size: 12px;
              font-weight: 500;
              letter-spacing: 0;
            }
          }
        }
        span {
          &.comment-icon {
            width: 25%;
            display: flex;
            color: $primary-color;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 16px;
            height: 53px;
            align-items: center;
            justify-content: center;
            border-left: 1px solid #000000;
          }
        }
      }
    }
  }
}

/* -----   CARD   ----- */

/* -----   PAGINATOR   ----- */
.most-visited-news-header-content .p-paginator .p-paginator-next,
.most-visited-news-header-content .p-paginator .p-paginator-prev,
.featured-news-header-content .p-paginator .p-paginator-next,
.featured-news-header-content .p-paginator .p-paginator-prev {
  background: $primary-color;
}
.featured-news-header-content .p-paginator,
.most-visited-news-header-content .p-paginator {
  background: none;
  .p-paginator-prev,
  .p-paginator-next {
    height: 47px;
    width: 47px;
  }
}
/* -----   PAGINATOR   ----- */
/* -----   TEXTAREA   ----- */
.news-details-comments-editor .p-inputtext,
.esport-details-comments-editor .p-inputtext {
  width: 100%;
}
.list-table.striped .p-inputtext {
  width: 60%;
  background: transparent;
  border: 1px solid #ffffff;
}
/* -----   TEXTAREA   ----- */

/* -----   OVERLAYPANEL   ----- */
// .main-header .p-overlaypanel .p-overlaypanel-content {
//   padding: 0;
//   width: 162px;
//   border-radius: 5px;
// }
// .main-header .p-overlaypanel {
//   background: #da063e;
//   border: none;
//   border-radius: 5px;
//   top: 78px !important; //fixed primeng bug
// }
// .main-header .p-overlaypanel:after,
// .main-header .p-overlaypanel:before {
//   border-bottom-color: #da063e;
// }
/* -----   OVERLAYPANEL   ----- */

.header-logo {
  background-image: url('https://cdn.stage.gamers.ba/assets/images/gamers_logo.webp');
  height: 48px;
  width: 259px;
}

.forum {
  &-title {
    color: $primary-color-text;
    font-family: Ubuntu, sans-serif;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    margin-bottom: 23px;
  }

  &-category {
    height: 80px;
    background-color: #161718;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);

    &-title {
      color: $primary-color-text;
      font-family: Ubuntu, sans-serif;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 28px;
    }

    &-info {
      color: $primary-color;
      font-family: Ubuntu, sans-serif;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 16px;
      &-text {
        color: $text-color-info;
      }
      &-numbers {
        color: $primary-color-text;
      }
    }
  }
}
.reply-comment {
  font-family: Ubuntu, sans-serif;
  background: #2a2c2d;
  color: white;
  padding: 5px;
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
  font-style: italic;
  font-size: 12px;
}
