//@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
//
//@font-face {
//  font-family: 'Ubuntu', sans-serif;
//}

@font-face {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 400;
  src: url('https://cdn.stage.gamers.ba/assets/fonts/Ubuntu-Regular.woff2.gz') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 700;
  src: url('https://cdn.stage.gamers.ba/assets/fonts/Ubuntu-Bold.woff2.gz') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 300;
  src: url('https://cdn.stage.gamers.ba/assets/fonts/Ubuntu-Light.woff2.gz') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  src: url('https://cdn.stage.gamers.ba/assets/fonts/Ubuntu-Medium.woff2.gz') format('woff2');
  font-display: swap;
}
