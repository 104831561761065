@import './_colors.scss';
@import 'primeflex/primeflex.scss';

/*
*
* AVATAR
*
*/

$avatar-xs: 65px;
$avatar-sm: 100px;
$avatar-md: 150px;
$avatar-lg: 200px;

$badge-xs: 21px;
$badge-sm: 28px;
$badge-md: 42px;
$badge-lg: 65px;

$avatar-sizes: (
  'xs': $avatar-xs,
  'sm': $avatar-sm,
  'md': $avatar-md,
  'lg': $avatar-lg,
) !default;

$badges-sizes: (
  'xs': $badge-xs,
  'sm': $badge-sm,
  'md': $badge-md,
  'lg': $badge-lg,
) !default;

@mixin avatarGenerator() {
  @each $key, $val in $avatar-sizes {
    .avatar-#{$key} {
      @include styleclass('relative border-circle');
      width: $val;
      height: $val;
      object-fit: cover;
      border-color: $primary-color;
      border-style: solid;

      @if $key == 'xs' {
        border-width: 2px;
      } @else if $key == 'sm' {
        border-width: 3px;
      } @else if $key == 'md' {
        border-width: 4px;
      } @else if $key == 'lg' {
        border-width: 5px;
      } @else {
        @error "Unknown badge size #{$key}.";
      }
    }
  }
}

@mixin badgeGenerator() {
  @each $key, $val in $badges-sizes {
    .avatar-#{$key}-badge {
      @include styleclass('flex align-items-end absolute right-0 bottom-0');

      img {
        width: $val;
        height: $val + 5;
      }

      span {
        @include styleclass(
          'flex justify-content-center align-items-center absolute text-white font-bold'
        );
        width: $val;
        height: $val + 5;

        @if $key == 'xs' {
          font-size: 13px;
        } @else if $key == 'sm' {
          font-size: 15px;
        } @else if $key == 'md' {
          font-size: 20px;
        } @else if $key == 'lg' {
          font-size: 33px;
        } @else {
          @error "Unknown badge size #{$key}.";
        }
      }
    }
  }
}

/*  
*
* BREAKPOINTS
*
*/

$breakpoints: (
  'sm': (
    min-width: 576px,
  ),
  'md': (
    min-width: 768px,
  ),
  'lg': (
    min-width: 1024px,
  ),
  'xl': (
    min-width: 1280px,
  ),
  '2xl': (
    min-width: 1536px,
  ),
) !default;

@mixin responsive($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Nema vrijednosti za `#{$breakpoint}`. "
        + "Dostupni breakpointsi su: #{map-keys($breakpoints)}.";
  }
}
